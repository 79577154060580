import { AlertManagerConfigOptions } from 'models/AlertManagerOptions';
import { RequestMethods } from './request-methods';

async function getExistingAlerts(configOptions: AlertManagerConfigOptions) {
  const url = configOptions.kapacitorEndpoint + '/tasks?pattern=' + configOptions.organizationName + '_*';

  try {
    const response = await fetch(url, {
      method: RequestMethods.Get,
      headers: {
        Authorization: 'Basic ' + btoa(`${configOptions.kapacitorAuthUser}:${configOptions.kapacitorAuthPass}`),
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function postTaskToKapacitor(params: any) {
  try {
    const response = await fetch(params.url, {
      method: RequestMethods.Post,
      headers: params.headers,
      body: params.data,
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function updateTaskStatusToKapacitor(alert: Alert, configOptions: AlertManagerConfigOptions) {
  const url = configOptions.kapacitorEndpoint + '/tasks/' + alert.id;
  const data = JSON.stringify({
    status: alert.status === 'enabled' ? 'disabled' : 'enabled',
  });
  try {
    const response = await fetch(url, {
      method: RequestMethods.Patch,
      headers: {
        Authorization: 'Basic ' + btoa(`${configOptions.kapacitorAuthUser}:${configOptions.kapacitorAuthPass}`),
      },
      body: data,
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
}

async function deleteTaskFromKapacitor(id: string, configOptions: AlertManagerConfigOptions) {
  try {
    const response = await fetch(configOptions.kapacitorEndpoint + '/tasks/' + id, {
      method: RequestMethods.Delete,
      headers: {
        Authorization: 'Basic ' + btoa(`${configOptions.kapacitorAuthUser}:${configOptions.kapacitorAuthPass}`),
      },
    });

    const r = await response;
    if (r) {
      return r;
    } else {
      return response.json();
    }
  } catch (error) {
    console.error(error);
  }
}

const kapacitorApi = {
  getExistingAlerts,
  postTaskToKapacitor,
  updateTaskStatusToKapacitor,
  deleteTaskFromKapacitor,
};
export default kapacitorApi;
