import { css } from 'emotion';
import React from 'react';
import { Alert } from './Alert';

interface Props {
  alerts: Alert[];
  handleMute: (alert: Alert) => void;
  handleDelete: (id: string) => void;
}
export const AlertList: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        height: 100%;
        width: 100%;
        overflow: auto;
      `}
    >
      {props.alerts?.map((alert, i) => {
        return <Alert key={i} alert={alert} handleMute={alert => props.handleMute(alert)} handleDelete={id => props.handleDelete(id)} />;
      })}
    </div>
  );
};
