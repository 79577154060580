export interface InputItem {
  label: string;
  name: string;
  infoMessage?: string;
  type?: string;
}

export const INPUT_DATA: Record<string, InputItem[]> = {
  general: [
    {
      label: 'Influx DB Name',
      name: 'influxDBName',
      infoMessage: 'The database we want to read from. Applies to all alerts created in this panel.',
    },
  ],
  kapacitor: [
    {
      label: 'API Root Url',
      name: 'kapacitorEndpoint',
    },
    {
      label: 'API Basic Auth User',
      name: 'kapacitorAuthUser',
      infoMessage: 'Leave blank if none.',
    },
    {
      label: 'API Basic Auth Password',
      name: 'kapacitorAuthPass',
      type: 'password',
      infoMessage: 'Leave blank if none.',
    },
    {
      label: 'Orgization ID',
      name: 'organizationName',
      infoMessage: 'Kapacitor tasks created in this panel will include this organization name in their ID.',
    },
    {
      label: 'Alert Output Measurement',
      name: 'alertOutputMeasurement',
      infoMessage: "The InfluxDB measurement where Kapacitor will write alerts for this panel's alert rules.",
    },
  ],
  maximo: [
    {
      label: 'API Root Url',
      name: 'maximoEndpoint',
    },
    {
      label: 'API Basic Auth User',
      name: 'maximoUser',
      infoMessage: 'Leave blank if none.',
    },
    {
      label: 'API Basic Auth Password',
      name: 'maximoPass',
      type: 'password',
      infoMessage: 'Leave blank if none.',
    },
    {
      label: 'Work Order Site ID',
      name: 'maximoSiteID',
      infoMessage: 'The site ID where work orders will be generated.',
    },
    {
      label: 'Work Order Location',
      name: 'maximoLocation',
      infoMessage: 'The location where work orders will be generated.',
    },
  ],
};
