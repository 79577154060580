import kapacitorApi from 'api/kapacitor-api';
import { css } from 'emotion';
import { NewAlertForm } from 'models/NewAlertForm';
import React, { ChangeEvent } from 'react';
import {
  ALERT_RULE_MEASUREMENT_OPTIONS,
  ALERT_RULE_TRIGGER_TAGS,
  DATA_FILTER_TYPES,
  LOCATIONS,
  MAXIMO_OPTIONS,
  MeasurementOptions,
} from 'shared/constants';
import { createAlert } from './utils/create-alert';
import { validateForm } from './utils/validate-form';
import { AlertManagerConfigOptions, AlertManagerMeasurementFieldsOption } from 'models/AlertManagerOptions';

interface Props {
  alerts: Alert[];
  configOptions: AlertManagerConfigOptions;
  measurementFieldsOptions: AlertManagerMeasurementFieldsOption[];
  alertNames: string[];
  handleCancel: () => void;
  handleSubmit: () => void;
}
export const AlertRuleForm: React.FC<Props> = (props: Props) => {
  const maximoOptions = MAXIMO_OPTIONS;
  const dataFilterTypes = DATA_FILTER_TYPES;
  const alertRuleTriggerTags = ALERT_RULE_TRIGGER_TAGS;
  const alertRuleMeasurementOptions = ALERT_RULE_MEASUREMENT_OPTIONS;
  const locations = LOCATIONS;
  const [form, setForm] = React.useState<NewAlertForm>({} as NewAlertForm);
  const [formError, setFormError] = React.useState<Record<string, boolean>>({});
  const [ruleNameError, setRuleNameError] = React.useState<boolean>(false);
  const [showChangeOptions, setShowChangeOptions] = React.useState<boolean>(false);

  const [measurementOptions, setMeasurementOptions] = React.useState<string[]>([]);
  const [fieldOptions, setFieldOptions] = React.useState<string[]>([]);

  const emptyOption = (
    <option
      className={css`
        color: rgba(black, 0.5);
      `}
      value=""
      disabled
      selected
      hidden
    >
      - Select -
    </option>
  );

  React.useEffect(() => {
    const measurementOptions = props.measurementFieldsOptions.map((o) => o.name);
    setMeasurementOptions(measurementOptions);
  }, [props.measurementFieldsOptions]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newForm = { ...form, [event.target.name]: event.target.value };
    setForm(newForm);
    validation(event.target.name);
  };

  const handleRuleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newForm = { ...form, [event.target.name]: event.target.value };
    setForm(newForm);
    const invalid = props.alertNames.includes(event.target.value);
    setRuleNameError(invalid);
    setFormError({ ...formError, ruleName: invalid });
  };

  const handleNameBlur = (name: string) => {
    if (ruleNameError) {
      return;
    }
    validation(name);
  };

  const handleEmailBlur = () => {
    const item = form['email'];
    const valid =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        item
      );
    setFormError({ ...formError, email: !valid });
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, phone: event.target.value || '' });
  };

  const handlePhoneBlur = () => {
    const item = form['phone'];
    const valid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(item);
    setFormError({ ...formError, phone: !valid });
  };

  const validation = (name: string) => {
    const item = form[name as keyof NewAlertForm];
    if (item || item === 0) {
      setFormError({ ...formError, [name]: false });
    }
    if (!item && item !== 0) {
      setFormError({ ...formError, [name]: true });
    }
  };

  const handleBlur = (name: string) => {
    validation(name);
  };

  const onSelectChange = (event: any) => {
    const newForm = { ...form, [event.target.name]: event.target.value };
    setForm(newForm);

    if (event.target.name === 'dataGroup') {
      const fieldOptions = props.measurementFieldsOptions.find((o) => o.name === event.target.value)?.fields || [];
      setFieldOptions(fieldOptions);
      // setAlertDataTypes(alertRuleTriggerOptions[event.target.value]);
    }
    if (event.target.name === 'measurement') {
      setShowChangeOptions(event.target.value === MeasurementOptions.Changes);
    }

    const errors = { ...formError };
    if (showChangeOptions) {
      delete errors.measurementOptionValue;
      setFormError({ ...formError, changeOptionValue: false, changeOptionsValue: false });
    } else {
      delete errors.changeOptionValue;
      delete errors.changeOptionsValue;
      setFormError({ ...errors, measurementOptionValue: false });
    }

    handleBlur(event.target.name);
  };

  const handleDataGroupClick = () => {
    if (!props.measurementFieldsOptions?.length) {
      confirm(
        'You currently have no measurement options. Edit this panel to add measurement options, fields, and tags.'
      );
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const validationData = validateForm(form, formError);
    console.log('========= FORM VALID ==========', validationData.valid);
    setFormError(validationData.errorMap);
    if (!validationData.valid) {
      return;
    }
    const alertTaskData = createAlert(form, props.configOptions);
    formatTaskForKapacitor(alertTaskData);
  };

  const formatTaskForKapacitor = (alerTaskData: any) => {
    const data = JSON.stringify({
      id: alerTaskData.taskID,
      type: 'stream',
      status: 'enabled',
      dbrps: [{ db: props.configOptions.influxDBName, rp: 'autogen' }],
      script: alerTaskData.taskScript,
    });
    const requestParams = {
      url: props.configOptions.kapacitorEndpoint + '/tasks',
      headers: { Authorization: 'Basic ' + btoa('timbergrove:Step334Outside') },
      data: data,
    };
    kapacitorApi.postTaskToKapacitor(requestParams).then((response) => {
      props.handleSubmit();
    });
  };

  const onCancel = (event: any) => {
    event.preventDefault();
    props.handleCancel();
  };

  return (
    <form
      className={css`
        width: 100%;
      `}
    >
      <div className="form form-group mt-sm">
        <form className="form-tg">
          <h4>Add an Alert Rule</h4>
          <div className="table-tg">
            <div className="group-tg-parent">
              <div className="group-tg">
                <label>Rule Name</label>
                <div className="clearfix"></div>
                <input
                  placeholder="e.g. Low Battery Warning"
                  className={`form-control form-input input-tg--wide ${formError.ruleName ? 'error' : ''}`}
                  name="ruleName"
                  onChange={handleRuleNameChange}
                  onBlur={() => handleNameBlur('ruleName')}
                  autoComplete="off"
                />
                {ruleNameError && (
                  <small style={{ color: '#d44a3a', marginLeft: '10px' }}>Rule name must be unique.</small>
                )}
              </div>
            </div>
            <h5>Who Should Receive the Alert?</h5>
            <div className="group-tg-parent">
              <div className="group-tg">
                <label>Email</label>
                <div className="clearfix"></div>
                <input
                  placeholder="recipient@email.com"
                  className={`form-control form-input input-tg--wide ${formError.email ? 'error' : ''}`}
                  name="email"
                  onChange={handleInputChange}
                  onBlur={() => handleEmailBlur()}
                />
              </div>
              <div className="group-tg">
                <label>Phone</label>
                <div className="clearfix"></div>
                <input
                  // type="number"
                  type="tel"
                  pattern="[0-9\-]+"
                  min={0}
                  placeholder="281-555-9999"
                  className={`form-control form-input input-tg--wide ${formError.phone ? 'error' : ''}`}
                  name="phone"
                  value={form.phone ?? ''}
                  onChange={handlePhoneChange}
                  onBlur={() => handlePhoneBlur()}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="group-tg-parent">
              <div className="group-tg">
                <label>Create work order in Maximo?</label>
                <div className="clearfix"></div>
                <select
                  name="maximoWorkOrder"
                  onChange={onSelectChange}
                  className={`form-control input-tg ${formError.maximoWorkOrder ? 'error' : ''}`}
                  onBlur={() => handleBlur('maximoWorkOrder')}
                >
                  {emptyOption}
                  {maximoOptions.map((o, i) => {
                    return (
                      <option key={i} value={o.id}>
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <h5>When Should the Alert Trigger?</h5>
            <div className="group-tg-parent">
              <div className="group-tg">
                <label>Send a</label>
                <select
                  name="alertType"
                  className={`form-control small-input input-tg ${formError.alertType ? 'error' : ''}`}
                  onChange={onSelectChange}
                  onBlur={() => handleBlur('alertType')}
                >
                  {emptyOption}
                  {dataFilterTypes.map((t, i) => {
                    return (
                      <option key={i} value={t.id}>
                        {t.label}
                      </option>
                    );
                  })}
                </select>{' '}
                alert
              </div>
            </div>
            <div className="group-tg-parent">
              <div className="group-tg">
                <label>when</label>
                <select
                  name="dataGroup"
                  className={`form-control small-input input-tg ${formError.dataGroup ? 'error' : ''}`}
                  onChange={onSelectChange}
                  onBlur={() => handleBlur('dataGroup')}
                  onClick={handleDataGroupClick}
                >
                  {emptyOption}
                  {measurementOptions.map((group, i) => {
                    return (
                      <option key={i} value={group}>
                        {group}
                      </option>
                    );
                  })}
                </select>

                <select
                  name="dataType"
                  className={`form-control small-input input-tg ${formError.dataType ? 'error' : ''}`}
                  onChange={onSelectChange}
                  onBlur={() => handleBlur('dataType')}
                >
                  {emptyOption}
                  {fieldOptions.map((t, i) => {
                    return (
                      <option key={i} value={t}>
                        {t}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="group-tg-parent">
              <div className="group-tg">
                <select
                  name="measurement"
                  className={`form-control input-tg ${formError.measurement ? 'error' : ''}`}
                  onChange={onSelectChange}
                  onBlur={() => handleBlur('measurement')}
                >
                  {emptyOption}
                  {alertRuleMeasurementOptions.map((o, i) => {
                    return <option key={i}>{o}</option>;
                  })}
                </select>
              </div>
              {!showChangeOptions && (
                <div className="group-tg">
                  <input
                    onChange={handleInputChange}
                    placeholder="Value"
                    type="number"
                    className={`form-control form-input input-tg ${formError.measurementOptionValue ? 'error' : ''}`}
                    name="measurementOptionValue"
                    onBlur={() => handleBlur('measurementOptionValue')}
                  />
                </div>
              )}
              {showChangeOptions && (
                <div className="group-tg">
                  <div className="subgroup-tg">
                    <input
                      onChange={handleInputChange}
                      placeholder="10"
                      type="number"
                      className={`form-control form-input input-tg ${formError.changeOptionValue ? 'error' : ''}`}
                      name="changeOptionValue"
                      onBlur={() => handleBlur('changeOptionValue')}
                    />
                    within a span of
                    <input
                      onChange={handleInputChange}
                      placeholder="120"
                      type="number"
                      className={`form-control form-input input-tg ${formError.changeOptionMinutes ? 'error' : ''}`}
                      name="changeOptionMinutes"
                      onBlur={() => handleBlur('changeOptionMinutes')}
                    />
                    minutes.
                  </div>
                </div>
              )}
            </div>
            <div className="clearfix"></div>
            <div className="group-tg-parent">
              <div className="group-tg">
                <label>
                  <em>(Optional)</em> Limit this alert rule to apply only where{' '}
                </label>
                <select className="form-control small-input input-tg" name="ruleLimitKey" onChange={onSelectChange}>
                  {emptyOption}
                  {alertRuleTriggerTags[form.dataGroup]?.map((t, i) => {
                    return (
                      <option key={i} value={t}>
                        {t}
                      </option>
                    );
                  })}
                </select>{' '}
                is equal to
                <select className="form-control small-input input-tg" name="ruleLimitValue" onChange={onSelectChange}>
                  {emptyOption}
                  {locations.map((t, i) => {
                    return (
                      <option key={i} value={t}>
                        {t}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="group-tg-parent" style={{ backgroundColor: 'inherit', boxShadow: 'none !important' }}>
              <div className="group-tg">
                <button className="submit-tg btn btn-success" onClick={handleSubmit}>
                  Create Alert
                </button>
                <button className="btn btn-danger btn-cancel" onClick={onCancel}>
                  Cancel
                </button>
                <br />
                {/* <span style={{ color: 'white' }}>
                  Thank you! Please wait while we submit your rule . . .
                </span>
                <span className="error-text">
                  Sorry! Something went wrong while submitting your request. Please try again later.
                </span> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </form>
  );
};
