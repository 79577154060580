import { css } from 'emotion';
import React, { ChangeEvent, MouseEvent, KeyboardEvent } from 'react';

type Props = {
  label: string;
  name?: string;
  handleFieldChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type?: string;
  infoMessage?: string;
  handleRemove?: (event: MouseEvent<HTMLButtonElement>) => void;
  handleEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
};

export const EditorInputItem: React.FC<Props> = (props) => {
  const [hoveredName, setHoveredName] = React.useState('');

  const handleMouseEnter = (name: string) => {
    setHoveredName(name);
  };

  const handleMouseLeave = () => {
    setHoveredName('');
  };

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (props.handleRemove) {
      props.handleRemove(event);
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (props.handleEnter) {
        props.handleEnter(e);
      }
    }
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 200px;
        max-width: 350px;
        margin-bottom: ${props.label.startsWith('Available') || !props.label ? '0' : '10px'};
        &:last-child {
          margin-bottom: 0;
        }
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
          `}
        >
          <label
            className={css`
              margin-bottom: 3px;
              font-size: 12px;
              white-space: nowrap;
              color: #989898;
              margin-left: 5px;
            `}
          >
            {props.label}
          </label>
          <input
            className={css`
              height: 40px;
              padding: 10px;
              border-radius: 4px;
              width: 100%;
            `}
            name={props.name}
            onChange={(e) => props.handleFieldChange(e)}
            value={props.value}
            type={props.type || 'text'}
            onKeyDown={handleEnter}
          />
        </div>
        {props.infoMessage && (
          <div
            className={css`
              margin: 18px 0 0 5px;
              cursor: pointer;
              display: flex;
              align-items: center;
            `}
            onMouseEnter={() => handleMouseEnter(props.name ?? '')}
            onMouseLeave={handleMouseLeave}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
              <defs>
                <filter id="a" width="165.6%" height="468.8%" x="-32.8%" y="-184.4%" filterUnits="objectBoundingBox">
                  <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1" />
                  <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="12" />
                  <feColorMatrix
                    in="shadowBlurOuter1"
                    result="shadowMatrixOuter1"
                    values="0 0 0 0 0.361271513 0 0 0 0 0.361271513 0 0 0 0 0.361271513 0 0 0 0.1 0"
                  />
                  <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter2" />
                  <feOffset dx="-24" in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
                  <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6" />
                  <feColorMatrix
                    in="shadowBlurOuter2"
                    result="shadowMatrixOuter2"
                    values="0 0 0 0 0.653249547 0 0 0 0 0.653249547 0 0 0 0 0.653249547 0 0 0 0.532260708 0"
                  />
                  <feMerge>
                    <feMergeNode in="shadowMatrixOuter1" />
                    <feMergeNode in="shadowMatrixOuter2" />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>
              <path
                fill="#fff"
                fillRule="nonzero"
                d="M12 4c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12h-2v-5h2v5zm-1-6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                filter="url(#a)"
                transform="translate(.5 .5)"
              />
            </svg>
          </div>
        )}
        {props.handleRemove && (
          <button
            onClick={handleRemove}
            className={css`
              all: unset;
              margin: ${props.label ? '18px 0 0 5px' : '0 0 0 5px'};
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              flex-grow: 0;
              flex-shrink: 0;
            `}
          >
            <div
              className={css`
                background-color: red;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              x
            </div>
          </button>
        )}
      </div>
      <div
        className={css`
          padding: 0 8px;
          height: 0;
          overflow: hidden;
          background-color: #016b93;
          border-radius: 6px;
          font-size: 11px;
          margin-top: 8px;
          transition: all 0.2s ease-in;
          display: flex;
          align-items: center;
          line-height: 17px;
          ${hoveredName === props.name &&
          css`
            transition: all 0.2s ease-in;
            height: 45px;
          `};
        `}
      >
        {props.infoMessage}
      </div>
    </div>
  );
};
