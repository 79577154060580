import { NewAlertForm } from 'models/NewAlertForm';
import { MeasurementOptions } from 'shared/constants';
import { REQUIRED_FIELDS } from './required-fields';

export function validateForm(
  form: NewAlertForm,
  formErrors: Record<string, boolean>
): { valid: boolean; errorMap: Record<string, boolean> } {
  console.log(
    form,
    form.measurement,
    form.measurement !== MeasurementOptions.Changes,
    form.measurement && form.measurement !== MeasurementOptions.Changes
  );
  const requiredFields =
    !!form.measurement && form.measurement !== MeasurementOptions.Changes
      ? REQUIRED_FIELDS.filter((f) => f !== 'changeOptionMinutes' && f !== 'changeOptionValue')
      : REQUIRED_FIELDS;
  console.log(requiredFields);
  const errorMap: Record<string, any> = {};
  requiredFields.forEach((f) => {
    errorMap[f] = formErrors[f] || false;
  });
  console.log('error map', errorMap);
  let valid = true;
  for (const error in errorMap) {
    if (errorMap[error]) {
      valid = false;
    }
  }
  console.log(valid, errorMap);
  return { valid, errorMap };
}
