import { AlertManagerConfigOptions } from 'models/AlertManagerOptions';

export const ALERT_MANAGER_DEFAULT_OPTIONS: AlertManagerConfigOptions = {
  influxDBName: 'mock_sensor_data',
  kapacitorEndpoint: 'https://kapacitor.crushdowntime.com/kapacitor/v1',
  kapacitorAuthUser: 'timbergrove',
  kapacitorAuthPass: 'Step334Outside',
  organizationName: 'TestOrg',
  alertOutputMeasurement: 'demo_alerts',
  maximoEndpoint: 'http://52.23.238.139',
  maximoUser: 'IOT-TIMBERGROVE',
  maximoPass: 'K{xfQ8AJ',
  maximoSiteID: 'BEDFORD',
  maximoLocation: 'OFF202',
};

export const PANEL_DEFAULTS = {
  organizationName: 'TestOrg',
  alertOutputMeasurement: 'alerts', // DB measurement where Kapacitor will write the alerts
  kapacitorEndpoint: 'https://kapacitor.crushdowntime.com/kapacitor/v1',
  kapacitorAuthUser: 'timbergrove',
  kapacitorAuthPass: 'Step334Outside',
  maximoEndpoint: 'http://52.23.238.139',
  maximoUser: 'IOT-TIMBERGROVE',
  maximoPass: 'K{xfQ8AJ',
  maximoSiteID: 'BEDFORD',
  maximoLocation: 'OFF202',
  influxDBName: 'bluebell',
  influxMeasurementName: 'katy_tx',
  influxMeasurementOptions: ['katy_tx', 'houston_tx', 'brenham_tx'],
  influxFieldOptions: [],
  measurement1: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement2: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement3: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement4: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement5: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement6: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement7: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
  measurement8: {
    name: '',
    field0: '',
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
    field7: '',
    field8: '',
    tag0: '',
    tag1: '',
    tag2: '',
    tag3: '',
    tag4: '',
    tag5: '',
  },
};

export const MAXIMO_OPTIONS: Array<{ label: string; id: number }> = [
  { label: 'No', id: 0 },
  { label: 'Yes', id: 1 },
];

export const DATA_FILTER_TYPES: Array<{ label: string; id: number }> = [
  { label: 'warning', id: 0 },
  { label: 'critical', id: 1 },
];

export const ALERT_RULE_TRIGGER_OPTIONS: Record<string, string[]> = {
  sensor_data: [
    'ambient_humidity',
    'ambient_temp',
    'batteryCharge',
    'freezer_temp',
    'freezer_humidity',
    'voltage1',
    'voltage2',
    'voltage3',
  ],
  Laird: ['humidity', 'temperature'],
};

export const ALERT_RULE_TRIGGER_TAGS: Record<string, string[]> = {
  sensor_data: ['location'],
  Laird: ['location'],
};

export const LOCATIONS: string[] = ['houston_tx', 'austin_tx', 'dallas_tx'];

export enum MeasurementOptions {
  LessThan = 'is less than',
  GreaterThan = 'is greater than',
  Changes = 'changes by more than',
}

export const ALERT_RULE_MEASUREMENT_OPTIONS: string[] = [
  MeasurementOptions.LessThan,
  MeasurementOptions.GreaterThan,
  MeasurementOptions.Changes,
];

export const DEFAULT_PANEL_OPTIONS: any = {
  alertOutputMeasurement: 'alerts', // DB measurement where Kapacitor will write the alerts
  kapacitorEndpoint: 'https://kapacitor.crushdowntime.com/kapacitor/v1',
  kapacitorAuthUser: 'timbergrove',
  kapacitorAuthPass: 'Step334Outside',
  maximoEndpoint: 'http://52.23.238.139',
  maximoUser: 'IOT-TIMBERGROVE',
  maximoPass: 'K{xfQ8AJ',
  maximoSiteID: 'BEDFORD',
  maximoLocation: 'OFF202',
  influxDBName: 'mock_sensor_data',
  influxMeasurementName: 'katy_tx',
  influxMeasurementOptions: ['katy_tx', 'houston_tx', 'brenham_tx'],
  influxFieldOptions: [],
};
