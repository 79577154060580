import { PanelPlugin } from '@grafana/data';
import { GeneralOptionsEditor } from 'components/Editor/GeneralOptionsEditor';
import { InfluxMeasurementOptionsEditor } from 'components/Editor/InfluxMeasurementOptionsEditor';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'configOptions',
      path: 'configOptions',
      name: '',
      editor: GeneralOptionsEditor,
    })
    .addCustomEditor({
      id: 'measurementFieldsOptions',
      path: 'measurementFieldsOptions',
      name: '',
      editor: InfluxMeasurementOptionsEditor,
    });
});
