import React from 'react';
import { css } from 'emotion';

type Props = {
  children: React.ReactNode;
};

export const EditorItem: React.FC<Props> = ({ children }) => {
  return (
    <div
      className={css`
        background-color: #1e1e1e;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        max-width: 370px;
        padding: 10px;
      `}
    >
      {children}
    </div>
  );
};

export const EditorSubItem: React.FC<Props> = ({ children }) => {
  return (
    <div
      className={css`
        background-color: rgba(37, 37, 37, 1);
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
          rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
        width: 100%;
        padding: 10px 10px 0 10px;
        margin-bottom: 15px;
      `}
    >
      {children}
    </div>
  );
};
