import { StandardEditorProps } from '@grafana/data';
import { css } from 'emotion';
import { AlertManagerConfigOptions } from 'models/AlertManagerOptions';
import React, { ChangeEvent, MouseEvent } from 'react';
import { ALERT_MANAGER_DEFAULT_OPTIONS } from 'shared/constants';
import { EditorItem, EditorSubItem } from './EditorItem';
import { EditorInputItem } from './EditorInputItem';
import { INPUT_DATA, InputItem } from './input-data';

export const GeneralOptionsEditor: React.FC<StandardEditorProps> = ({ item, value, onChange, context }) => {
  const defaultValues = ALERT_MANAGER_DEFAULT_OPTIONS;
  const [form, setForm] = React.useState<AlertManagerConfigOptions>({} as AlertManagerConfigOptions);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const inputData: Record<string, InputItem[]> = INPUT_DATA;

  if (!value) {
    onChange(defaultValues);
  }

  React.useEffect(() => {
    setButtonDisabled(true);
    setForm(value);
  }, [value]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setButtonDisabled(false);
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSaveOptions = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onChange({ ...form });
  };

  return (
    <>
      <form>
        <EditorItem>
          <div
            className={css`
              display: flex;
              align-items: center;
              margin-bottom: 20px;
            `}
          >
            <h3
              className={css`
                whitespace: nowrap;
                margin: 0;
              `}
            >
              Config Options
            </h3>
            <button
              onClick={handleSaveOptions}
              className={css`
                all: unset;
                cursor: pointer;
                position: sticky;
                top: 0;
                z-index: 100;
                background: rgb(49, 208, 80);
                color: #fff;
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 0 8px;
                margin-left: 15px;
                &:disabled {
                  background: rgb(170, 170, 170);
                }
              `}
              disabled={buttonDisabled}
            >
              Update
            </button>
          </div>
          <EditorSubItem>
            <h4
              className={css`
                whitespace: nowrap;
                margin: 0 0 10px 0;
              `}
            >
              General Options
            </h4>
            {inputData.general.map((e, i) => {
              return (
                <EditorInputItem
                  key={i}
                  label={e.label}
                  name={e.name}
                  handleFieldChange={(e) => handleFieldChange(e)}
                  value={form[e.name]}
                  infoMessage={e.infoMessage}
                />
              );
            })}
          </EditorSubItem>

          <EditorSubItem>
            <h4
              className={css`
                whitespace: nowrap;
                margin: 0 0 10px 0;
              `}
            >
              Kapacitor Configuration
            </h4>
            {inputData.kapacitor.map((e, i) => {
              return (
                <EditorInputItem
                  key={i}
                  label={e.label}
                  name={e.name}
                  handleFieldChange={(e) => handleFieldChange(e)}
                  value={form[e.name]}
                  type={e.type || 'text'}
                  infoMessage={e.infoMessage}
                />
              );
            })}
          </EditorSubItem>

          <EditorSubItem>
            <h4
              className={css`
                whitespace: nowrap;
                margin: 0 0 10px 0;
              `}
            >
              Maximo Configuration
            </h4>
            {inputData.maximo.map((e, i) => {
              return (
                <EditorInputItem
                  key={i}
                  label={e.label}
                  name={e.name}
                  handleFieldChange={(e) => handleFieldChange(e)}
                  value={form[e.name]}
                  type={e.type || 'text'}
                  infoMessage={e.infoMessage}
                />
              );
            })}
          </EditorSubItem>
        </EditorItem>
      </form>
    </>
  );
};
