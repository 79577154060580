export const REQUIRED_FIELDS = [
  'ruleName',
  'email',
  'phone',
  'alertType',
  'dataGroup',
  'dataType',
  'measurement',
  'measurementOptionValue',
  'changeOptionValue',
  'changeOptionMinutes',
];
