import { StandardEditorProps } from '@grafana/data';
import React, { ChangeEvent, MouseEvent } from 'react';
import { css } from 'emotion';
import { InfluxMeasurementOption } from 'models/InfluxMeasurementOption';
import { PanelButton } from 'components/PanelButton';
import { EditorItem, EditorSubItem } from './EditorItem';
import { EditorInputItem } from './EditorInputItem';
// import { getDataSourceSrv } from '@grafana/runtime';

export const InfluxMeasurementOptionsEditor: React.FC<StandardEditorProps<InfluxMeasurementOption[]>> = ({
  item,
  value,
  onChange,
  context,
}) => {
  if (!value) {
    onChange([] as InfluxMeasurementOption[]);
  }

  // //to get db stuff
  // const dsSrv = getDataSourceSrv();
  // const dsList = dsSrv.getList();
  // console.log(dsList);

  const [form, setForm] = React.useState<InfluxMeasurementOption[]>([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    setButtonDisabled(true);
    setForm(value);
  }, [value]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    const newForm = [...form];
    newForm[index].name = event.target.value;
    setForm(newForm);
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>, index: number, fieldIndex: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    const newForm = [...form];
    newForm[index].fields[fieldIndex] = event.target.value;
    setForm(newForm);
  };

  const handleTagChange = (event: ChangeEvent<HTMLInputElement>, index: number, tagIndex: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    const newForm = [...form];
    newForm[index].tags[tagIndex] = event.target.value;
    setForm(newForm);
  };

  const handleAddNewItem = () => {
    setButtonDisabled(false);
    const newForm = [...form];
    newForm.push({ name: '', fields: [''], tags: [''] });
    setForm(newForm);
  };

  const handleRemoveItem = (event: MouseEvent<HTMLButtonElement>, index: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    const userResponse = confirm('Are you sure you want to remove this measurement?');
    if (userResponse) {
      const newForm = [...form];
      newForm.splice(index, 1);
      setForm(newForm);
    }
  };

  const handleRemoveField = (event: MouseEvent<HTMLButtonElement>, itemIndex: number, fieldIndex: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    const newForm = [...form];
    newForm[itemIndex].fields.splice(fieldIndex, 1);
    setForm(newForm);
  };

  const handleAddField = (event: MouseEvent<HTMLButtonElement>, itemIndex: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    addField(itemIndex);
  };

  const addField = (index: number) => {
    const newForm = [...form];
    newForm[index].fields.push('');
    setForm(newForm);
  };

  const handleAddTag = (event: MouseEvent<HTMLButtonElement>, itemIndex: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    addTag(itemIndex);
  };

  const addTag = (index: number) => {
    const newForm = [...form];
    newForm[index].tags.push('');
    setForm(newForm);
  };

  const handleRemoveTag = (event: MouseEvent<HTMLButtonElement>, itemIndex: number, fieldIndex: number) => {
    setButtonDisabled(false);
    event.preventDefault();
    const newForm = [...form];
    newForm[itemIndex].tags.splice(fieldIndex, 1);
    setForm(newForm);
  };

  const handleSaveOptions = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onChange([...form]);
  };

  return (
    <>
      <EditorItem>
        <div
          className={css`
            display: flex;
            align-items: center;
            margin-bottom: 30px;
          `}
        >
          <h3
            className={css`
              whitespace: nowrap;
              margin: 0;
            `}
          >
            Measurement/Fields
          </h3>
          <button
            onClick={handleSaveOptions}
            className={css`
              all: unset;
              cursor: pointer;
              position: sticky;
              top: 0;
              z-index: 100;
              background: rgb(49, 208, 80);
              color: #fff;
              display: flex;
              align-items: center;
              border-radius: 4px;
              padding: 0 8px;
              margin-left: 15px;
              &:disabled {
                background: rgb(170, 170, 170);
              }
            `}
            disabled={buttonDisabled}
          >
            Update
          </button>
        </div>
        <form>
          {form?.map((item, i) => {
            return (
              <EditorSubItem key={i}>
                <div
                  className={css`
                    display: flex;
                    flex-direction: column;
                    padding: 8px;
                    margin-bottom: 10px;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      margin-bottom: 10px;
                    `}
                  >
                    <h4
                      className={css`
                        margin: 0;
                      `}
                    >
                      Measurement {i + 1}
                    </h4>
                    <button
                      onClick={(e) => handleRemoveItem(e, i)}
                      className={css`
                        border: none;
                        font-size: 20px;
                        background-color: red;
                        border-radius: 50%;
                        height: 30px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      `}
                    >
                      x
                    </button>
                  </div>
                  <EditorInputItem
                    label={'Name'}
                    name={'name'}
                    handleFieldChange={(e) => handleNameChange(e, i)}
                    value={item.name ?? ''}
                    infoMessage={'This measurement must exist within the DB you chose on the Options tab.'}
                  />
                  {item.fields.map((field, fieldIndex) => {
                    return (
                      <EditorInputItem
                        key={fieldIndex}
                        label={(fieldIndex === 0 && 'Available Fields') || ''}
                        name={'field'}
                        handleFieldChange={(e) => handleFieldChange(e, i, fieldIndex)}
                        value={field}
                        infoMessage={
                          (fieldIndex === 0 &&
                            'These should be the field names from the measurement you defined above.') ||
                          ''
                        }
                        handleRemove={item.fields.length > 1 ? (e) => handleRemoveField(e, i, fieldIndex) : undefined}
                        handleEnter={() => addField(i)}
                      />
                    );
                  })}
                  <button
                    onClick={(e) => handleAddField(e, i)}
                    className={css`
                      border: none;
                      font-size: 12px;
                      background-color: green;
                      border-radius: 4px;
                      height: 26px;
                      max-width: 70px;
                      margin-bottom: 20px;
                    `}
                  >
                    + Field
                  </button>
                  {item.tags.map((tag, tagIndex) => {
                    return (
                      <EditorInputItem
                        key={tagIndex}
                        label={(tagIndex === 0 && 'Available Tags') || ''}
                        name={'field'}
                        handleFieldChange={(e) => handleTagChange(e, i, tagIndex)}
                        value={tag}
                        infoMessage={
                          (tagIndex === 0 && 'These should be the tag names from the measurement you defined above.') ||
                          ''
                        }
                        handleRemove={item.tags.length > 1 ? (e) => handleRemoveTag(e, i, tagIndex) : undefined}
                        handleEnter={() => addTag(i)}
                      />
                    );
                  })}
                  <button
                    onClick={(e) => handleAddTag(e, i)}
                    className={css`
                      border: none;
                      font-size: 12px;
                      background-color: green;
                      border-radius: 4px;
                      height: 26px;
                      max-width: 70px;
                    `}
                  >
                    + Tag
                  </button>
                </div>
              </EditorSubItem>
            );
          })}
        </form>
        <PanelButton text="Add new measurement option" handleClick={handleAddNewItem}></PanelButton>
      </EditorItem>
      <div
        className={css`
          margin-bottom: 100px;
        `}
      ></div>
    </>
  );
};
