import { PanelProps } from '@grafana/data';
import kapacitorApi from 'api/kapacitor-api';
import { AlertList } from 'components/AlertList';
import { AlertRuleForm } from 'components/AlertRuleForm';
import { PanelButton } from 'components/PanelButton';
import { css } from 'emotion';
import { AlertManagerOptions } from 'models/AlertManagerOptions';
import React from 'react';
import './SimplePanel.css';

interface Props extends PanelProps<AlertManagerOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  // const t = getDataSourceSrv();
  //Gets user info: config.bootData.user
  // console.log('grafana user data', config.bootData);
  //use config.bootData.user.orgName to get current org name
  // const panelOptions = { ...config.bootData.user, ...DEFAULT_PANEL_OPTIONS };
  const [alerts, setAlerts] = React.useState<Alert[]>([]);
  const [alertNames, setAlertNames] = React.useState<string[]>([]);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const configOptions = options.configOptions;
  const measurementFieldsOptions = options.measurementFieldsOptions;

  const getExistingAlerts = React.useCallback(() => {
    kapacitorApi.getExistingAlerts(configOptions).then((response) => {
      setAlerts(response.tasks);
      const newAlertNames = response.tasks.map((task: Alert) =>
        task.id.replace(`${configOptions.kOrganizationId}_`, '').replace(/_/g, ' ')
      );
      setAlertNames(newAlertNames);
    });
  }, [configOptions]);

  React.useEffect(() => {
    if (configOptions) {
      getExistingAlerts();
    }
  }, [getExistingAlerts, configOptions]);

  const handleAddAlertRule = () => {
    setShowForm(true);
  };

  const handleMuteAlert = (alert: Alert) => {
    kapacitorApi.updateTaskStatusToKapacitor(alert, configOptions).then((response) => {
      getExistingAlerts();
    });
  };

  const handleDeleteAlert = (id: string) => {
    kapacitorApi.deleteTaskFromKapacitor(id, configOptions).then((response) => {
      getExistingAlerts();
    });
  };

  const handleCancel = () => {
    setShowForm(false);
  };

  const handleSubmit = () => {
    setShowForm(false);
    getExistingAlerts();
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow-y: auto;
      `}
    >
      {showForm && (
        <AlertRuleForm
          handleCancel={handleCancel}
          handleSubmit={() => handleSubmit()}
          alerts={alerts}
          configOptions={configOptions}
          measurementFieldsOptions={measurementFieldsOptions}
          alertNames={alertNames}
        />
      )}
      <h4>My Alert Rules</h4>
      {!showForm && (
        <div>
          <PanelButton text="+ Add an Alert Rule" backgroundColor="#31D050" handleClick={() => handleAddAlertRule()} />
        </div>
      )}

      <AlertList alerts={alerts} handleMute={handleMuteAlert} handleDelete={handleDeleteAlert} />
    </div>
  );
};
