import { AlertManagerConfigOptions } from 'models/AlertManagerOptions';
import { NewAlertForm } from 'models/NewAlertForm';
import { MeasurementOptions } from 'shared/constants';

// interface AlertRule {
//     name: string;
//     email1: string;
//     phone1: string;
//     maximo: string;
//     measurement: string;
//     evaluatorType: string;
//     evaluatorID: string;
//     metric: string;
//     dataFilterType: string;
//     filterID: string;
//     evaluatorValue1: number;
//     evaluatorValue2: number;
//     whereFilter: any;
// }

export function createAlert(form: NewAlertForm, configOptions: AlertManagerConfigOptions) {
  console.log('--Creating alert object from form data...');
  console.log('Form is', form);

  const newAlertRule: any = {};
  // Allow only letters, numbers, underscore, and spaces
  // Also preface every name with the organization name
  newAlertRule.name = configOptions.organizationName + '_' + form.ruleName.replace(/[^\w\s]/g, '');
  newAlertRule.email1 = form.email;
  newAlertRule.phone1 = form.phone;
  newAlertRule.maximo = form.maximoWorkOrder;
  // NOTE: For supervisor notification feature
  // newAlertRule.hasSecondary = this.form.hasSecondary
  // newAlertRule.email2 = this.form.email2
  // newAlertRule.phone2 = this.form.phone2
  newAlertRule.dataFilterType = form.alertType === '0' ? 'warn' : 'crit';
  newAlertRule.measurement = form.dataGroup;
  newAlertRule.metric = form.dataType;
  // newAlertRule.evaluatorType = form.measurement;
  if (form.measurement === MeasurementOptions.LessThan) {
    newAlertRule.evaluatorType = '0';
  }
  if (form.measurement === MeasurementOptions.GreaterThan) {
    newAlertRule.evaluatorType = '1';
  }
  if (form.measurement === MeasurementOptions.Changes) {
    newAlertRule.evaluatorType = '5';
  }
  // newAlertRule.evaluatorID = this.form.evaluatorType.id;
  // newAlertRule.filterID = this.form.dataFilterType.id;
  newAlertRule.evaluatorValue1 = form.measurementOptionValue;
  if (form.measurement === MeasurementOptions.Changes) {
    newAlertRule.evaluatorValue2 = form.changeOptionMinutes;
  } else {
    newAlertRule.evaluatorValue2 = '';
  }
  if (!!form.ruleLimitKey && !!form.ruleLimitValue) {
    newAlertRule.whereFilter = `("${form.ruleLimitKey}" == '${form.ruleLimitValue}')`;
  } else {
    newAlertRule.whereFilter = 'TRUE'; // Applies no filter in task template
  }
  console.log('New Alert Rule wherefilter', newAlertRule.whereFilter);
  // TODO: Move this out into a separate function?
  let sentTo = [];
  if (!!form.email) {
    sentTo.push(form.email);
  }
  if (!!form.phone) {
    sentTo.push(form.phone);
  }
  if (form.maximoWorkOrder === '1') {
    sentTo.push('Maximo');
  }
  newAlertRule.sentTo = sentTo.join(' and ');
  let msgString = `*Alerts ${sentTo} when ${form.dataGroup} ${form.dataType} ${form.measurement} ${form.measurementOptionValue}`;
  if (form.measurement === MeasurementOptions.Changes) {
    msgString += ` in a span of ${form.changeOptionMinutes} minutes`;
  }
  if (newAlertRule.whereFilter === 'TRUE') {
    msgString += '.$';
  } else {
    msgString += ` for ${form.ruleLimitKey} ${form.ruleLimitValue}.$`;
  }
  newAlertRule.message = msgString;
  return createAlertTask(newAlertRule, configOptions);
}

// Create the Kapacitor Task that will process streaming data
// and issue our alert when the criteria is met.
export function createAlertTask(alertRule: any, configOptions: AlertManagerConfigOptions) {
  console.log('--Creating alert task from alertRule object...', alertRule);
  // Kapacitor does not accept spaces for Task ID
  const taskID = alertRule.name.replace(/ /g, '_');
  // TODO: Tagkey and tagVal are hardcoded
  let taskParams: any = {
    id: taskID,
    message: alertRule.message,
    email: alertRule.email1,
    phone: alertRule.phone1,
    thresholdValue: alertRule.evaluatorValue1,
    metric: alertRule.metric,
    level: alertRule.dataFilterType,
    maximo: alertRule.maximo,
    sentTo: alertRule.sentTo,
    measurement: alertRule.measurement,
    whereFilter: alertRule.whereFilter,
  };
  let taskScript = '';
  console.log(alertRule.evaluatorType);
  switch (alertRule.evaluatorType) {
    case '0': // Less Than
      taskParams.operand = '<';
      taskScript = buildThresholdTaskFromTemplate(taskParams, configOptions);
      break;
    case '1': // Greater Than
      taskParams.operand = '>';
      taskScript = buildThresholdTaskFromTemplate(taskParams, configOptions);
      break;
    //   case 4: // Deadman
    //     console.log("Need to build deadman template");
    //     // taskScript = this.buildDeadmanTaskFromTemplate()
    //     break;
    case '5': // Relative threshold
      taskParams.timeSpan = alertRule.evaluatorValue2;
      taskScript = buildRelativeThresholdTaskFromTemplate(taskParams, configOptions);
      break;
    default:
      console.error('Hit undefined case during createAlertTask.');
  }
  console.log('Task script is', taskScript);
  return { taskID, taskScript };
  // this.postTaskToKapacitor(taskID, this.panel.influxDBName, taskScript, this.panel.kapacitorEndpoint).then(result => {
  //   console.log("Got the result", result);
  //   this.formFlags.alertRuleCreated = true;
  //   this.formFlags.pleaseWait = false;
  //   this.formFlags.showForm = false;
  //   this.resetForm();
  // }).catch(error => {
  //   console.error("Error posting task to Kapacitor:", error);
  //   this.formFlags.pleaseWait = false;
  //   this.formFlags.submitError = true;
  // });
}

function buildThresholdTaskFromTemplate(a: any, configOptions: AlertManagerConfigOptions) {
  // TODO: That lambda filter should include a tag key/value
  const templatePt1 = `
      var alertRuleMsg = '${a.message}'
      var db = '${configOptions.influxDBName}'
      var rp = 'autogen'
      var measurement = '${a.measurement}'
      var groupBy = []
      var whereFilter = lambda: ${a.whereFilter}
      var name = '${a.id}'
      var idVar = name + ' Alert'
      var message = '${configOptions.organizationName} {{.Level}} Alert: {{.ID}}'
      var idTag = 'alertID'
      var levelTag = 'level'
      var messageField = 'message'
      var durationField = 'duration'
      var outputDB = 'chronograf'
      var outputRP = 'autogen'
      var outputMeasurement = '${configOptions.alertOutputMeasurement}'
      var triggerType = 'threshold'
      var details = '${configOptions.organizationName} Sensor Alert: ${
    a.metric
  } is now in the {{.Level}} state.<br><br> ${
    a.metric
  } value: {{ index .Fields "value" }}.<br><br> To mute this alert, click http://alerts.crushdowntime.com/?id=${
    a.id
  }&token=${btoa('timbergrove:Step334Outside')} <br><br>Alert was triggered at {{ .Time }}.<br> Alert was sent to ${
    a.sentTo
  }.'
      var ${a.level} = ${a.thresholdValue}
      var data = stream
          |from()
              .database(db)
              .retentionPolicy(rp)
              .measurement(measurement)
              .groupBy(groupBy)
              .where(whereFilter)
          |eval(lambda: "${a.metric}")
              .as('value')
      var trigger = data
          |alert()
              .${a.level}(lambda: "value" ${a.operand} ${a.level})
              .stateChangesOnly()
              .message(message)
              .id(idVar)
              .idTag(idTag)
              .levelTag(levelTag)
              .messageField(messageField)
              .durationField(durationField)
              .details(details)
              .email('${a.email}')
              .post('https://9bne8au6m8.execute-api.us-east-1.amazonaws.com/Dev/IoT_SMS')
              `;

  // If the user has decided to integrate maximo, we'll post a work order for the alert.
  const templatePt2 = a.maximo
    ? `.post('${configOptions.maximoEndpoint}/maxrest/rest/os/mxwo?_lid=${configOptions.maximoUser}&_lpwd=${
        configOptions.maximoPass
      }&_format=json&description=${encodeURI(a.message)}&siteid=${configOptions.maximoSiteID}&location=${
        configOptions.maximoLocation
      }')
      `
    : ``;

  const templatePt3 = `trigger
          |eval(lambda: float("value"))
              .as('value')
              .keep()
          |influxDBOut()
              .create()
              .database(outputDB)
              .retentionPolicy(outputRP)
              .measurement(outputMeasurement)
              .tag('alertName', name)
              .tag('triggerType', triggerType)
      trigger
          |httpOut('output')`;
  const thresholdTaskTemplate = templatePt1 + templatePt2 + templatePt3;
  return thresholdTaskTemplate;
}

function buildRelativeThresholdTaskFromTemplate(a: any, configOptions: any) {
  const templatePt1 = `
      var alertRuleMsg = '${a.message}'
      var db = '${configOptions.influxDBName}'
      var rp = 'autogen'
      var measurement = '${a.measurement}'
      var groupBy = []
      var whereFilter = lambda: ${a.whereFilter}
      var name = '${a.id}'
      var idVar = name + ' Alert'
      var message = '${configOptions.organizationName} {{.Level}} Alert: {{.ID}}'
      var idTag = 'alertID'
      var levelTag = 'level'
      var messageField = 'message'
      var durationField = 'duration'
      var outputDB = 'chronograf'
      var outputRP = 'autogen'
      var outputMeasurement = '${configOptions.alertOutputMeasurement}'
      var triggerType = 'relative'
      var details = '${configOptions.organizationName} Sensor Alert: ${
    a.metric
  } is now in the {{.Level}} state.<br><br> ${
    a.metric
  } value: {{ index .Fields "value" }}.<br><br> You are receiving this relative threshold alert due to an abnormal fluctuation in your data. To mute this alert, click http://alerts.crushdowntime.com/?id=${
    a.id
  }&token=${btoa('timbergrove:Step334Outside')} <br><br>Alert was triggered at {{ .Time }}.<br> Alert was sent to ${
    a.sentTo
  }.'
      var shift = 30m
      var ${a.level} = ${a.thresholdValue}
      var data = stream
          |from()
              .database(db)
              .retentionPolicy(rp)
              .measurement(measurement)
              .groupBy(groupBy)
              .where(whereFilter)
          |eval(lambda: "${a.metric}")
              .as('value')
      var past = data
          |shift(shift)
      var current = data
      var trigger = past
          |join(current)
              .as('past', 'current')
          |eval(lambda: float("current.value" - "past.value"))
              .keep()
              .as('value')
          |alert()
              .${a.level}(lambda: "value" > ${a.level})
              .stateChangesOnly()
              .message(message)
              .id(idVar)
              .idTag(idTag)
              .levelTag(levelTag)
              .messageField(messageField)
              .durationField(durationField)
              .details(details)
              .email('${a.email}')
              .post('https://9bne8au6m8.execute-api.us-east-1.amazonaws.com/Dev/IoT_SMS')
              `;

  // If the user has decided to integrate maximo, we'll post a work order for the alert.
  const templatePt2 = a.maximo
    ? `.post('${configOptions.maximoEndpoint}/maxrest/rest/os/mxwo?_lid=${configOptions.maximoUser}&_lpwd=${
        configOptions.maximoPass
      }&_format=json&description=${encodeURI(a.message)}&siteid=${configOptions.maximoSiteID}&location=${
        configOptions.maximoLocation
      }')
    `
    : ``;

  const templatePt3 = `
      trigger
          |eval(lambda: float("value"))
              .as('value')
              .keep()
          |influxDBOut()
              .create()
              .database(outputDB)
              .retentionPolicy(outputRP)
              .measurement(outputMeasurement)
              .tag('alertName', name)
              .tag('triggerType', triggerType)
      trigger
          |httpOut('output')`;
  const thresholdTaskTemplate = templatePt1 + templatePt2 + templatePt3;
  return thresholdTaskTemplate;
}
