export function parseAlertMessageFromTask(alert: any) {
  const orgNameEnd = alert.id.indexOf('_') + 1;
  const msgStart = alert.script.indexOf('*') + 1;
  const msgEnd = alert.script.indexOf('$');
  const alertInfo = {
    alertRuleMsg: alert.script.slice(msgStart, msgEnd),
    displayName: alert.id.slice(orgNameEnd).replace(/_/g, ' '),
    id: alert.id,
    status: alert.status,
  };
  // Exclude any test/debug alerts created in Chronograf
  if (alertInfo.id.includes('chronograf')) {
    return;
  }
  return alertInfo;
}
