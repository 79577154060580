import { Button } from '@grafana/ui';
import { css } from 'emotion';
import React from 'react';

interface Props {
  text?: string;
  backgroundColor?: string;
  width?: number;
  handleClick?: () => void;
}
export const PanelButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      className={css`
        background-color: ${props.backgroundColor || 'green'};
        width: ${props.width || 'auto'};
        &:hover {
          background-color: ${props.backgroundColor || 'green'};
          filter: brightness(120%);
        }
      `}
      onClick={props.handleClick}
    >
      {props.text}
    </Button>
  );
};
