import { css } from 'emotion';
import React from 'react';

interface Props {
  muted: boolean;
  handleMute: () => void;
}
export const AlertButton: React.FC<Props> = (props: Props) => {
  const greenColor = 'rgb(1 179 1)';
  const orangeColor = 'orange';
  return (
    <button
      className={css`
        border: 1px solid ${(props.muted && greenColor) || orangeColor};
        border-radius: 4px;
        background-color: transparent;
        color: ${(props.muted && greenColor) || orangeColor};
        &:hover {
          filter: brightness(120%);
        }
      `}
      onClick={props.handleMute}
    >
      {(props.muted && 'unmute this alert') || 'mute this alert'}
    </button>
  );
};
