import { css } from 'emotion';
import React from 'react';
import { AlertButton } from './AlertButton';
import { PanelButton } from './PanelButton';
import { parseAlertMessageFromTask } from './utils/parse-alert-message';

interface Props {
  alert: Alert;
  handleMute: (alert: Alert) => void;
  handleDelete: (id: string) => void;
}

export const Alert: React.FC<Props> = (props: Props) => {
  const [alert, setAlert] = React.useState<Alert>();

  React.useEffect(() => {
    const t = parseAlertMessageFromTask(props.alert);
    setAlert(t);
  }, [props.alert])

  const handleDelete = () => {
    props.handleDelete(props.alert.id);
  }

  return (
    <div
      className={css`
        padding: 16px;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
        background: linear-gradient(to bottom right, rgba(50, 50, 50, 0.2), rgba(60, 60, 60, 0.7)) !important;
        list-style-type: none;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #666;
        border-radius: 4px;
      `}
    >
      <div className={css``}>
        <div
          className={css`
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 4px;
          `}
        >
          {alert?.displayName}
        </div>
        <div
          className={css`
            font-size: 12px;
            margin-bottom: 4px;
          `}
        >
          {alert?.alertRuleMsg}
        </div>
        <div>
          <AlertButton muted={props.alert.status === 'disabled'} handleMute={() => props.handleMute(props.alert)} />
        </div>
      </div>
      <div
        className={css`
          margin-left: 4px;
        `}
      >
        <PanelButton text="x" backgroundColor="red" handleClick={handleDelete} />
      </div>
    </div>
  );
};

// .alert-tg {
//     padding: 1rem 1.5rem;
//     text-shadow: 0 1px 0 rgba(0,0,0,.2);
//     background: linear-gradient(to bottom right, rgba(50, 50, 50, 0.2), rgba(60, 60, 60, 0.7)) !important;
//     list-style-type: none;
//     margin-bottom: 1rem;
//     display: flex;
//     justify-content: space-between;
//     flex-flow: row-reverse nowrap;
//     border: 1px solid #666;
//   }
